<template>
  <v-container>
    <div>
      <h1 v-html="$t('translations_edit_title')"></h1>
      <v-textarea id="search_translation_text_de" v-model="model.textDe" :label="$t('search_translation_text_de')" type="text" @keyup.enter="search" />
      <v-textarea id="search_translation_text_fr" v-model="model.textFr" :label="$t('search_translation_text_fr')" type="text" @keyup.enter="search" />
      <v-textarea id="search_translation_text_it" v-model="model.textIt" :label="$t('search_translation_text_it')" type="text" @keyup.enter="search" />
      <v-text-field
        id="search_translation_message_key"
        v-model="model.messageKey"
        :label="$t('search_translation_message_key')"
        type="text"
        @keyup.enter="search"
      />
      <v-btn color="secondary" @click="goBack"><span v-html="$t('translation_button_back')" /></v-btn>
      <v-btn color="primary" :loading="loading" @click="submit"><span v-html="$t('translations_button_save')" /></v-btn>
    </div>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '../../main'
import _ from 'lodash'

const defaultData = {
  nameDe: 'textDe',
  nameFr: 'textFr',
  nameIt: 'textIt'
}
import BaseMixin from '../../utils/BaseMixin'
import translationExample from './translationExample.json'
import { showError } from '@/services/axios'
import { language } from '@/services/language'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'translations',
  data() {
    return {
      apiURL: apiURL + '/translations/',
      allItems: [],
      totalElements: 0,
      term: { nameDE: '', pos: '' },
      model: defaultData,
      translationGroups: [],
      selected: undefined,
      loading: false
    }
  },
  mixins: [BaseMixin],
  methods: {
    openModal() {
      this.$refs.myModal.show()
    },
    goBack() {
      this.$router.push({ name: 'translations_read' }).catch(() => {
        /* duplicated route */
      })
    },
    findByValues(collection, property, values) {
      return _.filter(collection, function (item) {
        return _.has(values, item[property])
      })
    },
    async loadtranslation(translationId) {
      try {
        const result = await this.axios.get((this.apiURL += translationId), _.pick(this.model, _.keys(translationExample)), {
          headers: { 'Content-Type': 'application/json' }
        })
        this.model = result.data
      } catch (e) {
        showError(e)
      }
    },
    async submit(e) {
      e.preventDefault()
      this.loading = false
      try {
        const resultx = await this.axios.patch(this.apiURL, _.pick(this.model, _.keys(translationExample)), { headers: { 'Content-Type': 'application/json' } })
        language.loadTranslations(this.$route.params.lang, true)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      // im just a hook
    }
  },
  async mounted() {
    const translationId = this.$route.params.id
    this.translation = await this.loadtranslation(translationId)
  }
})
</script>

<style lang="scss" scoped>
option:disabled {
  background-color: rgba(192, 192, 192, 0.788);
}
</style>
